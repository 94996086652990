<template>
  <abnormal-warning :datas="[1, 'viewGdsException', 'editGdsException']"></abnormal-warning>
</template>
<script>
import AbnormalWarning from '@/components/threshold/AbnormalWarning.vue'
export default {
  components: {
    AbnormalWarning,
  },
  data() {
    return {}
  },
  mounted() {},
}
</script>
